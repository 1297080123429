import { Link } from 'components';
import { axiosConfig } from 'config';
import React, { useEffect, useState } from 'react';
import * as api from "services";
import packageJson from '../../../package.json';
import { FooterWrapper } from './footer.style.js';

// import facebook from './icons/facebook-white.svg';
// import twitter from './icons/twitter-white.svg';
// import youtube from './icons/youtube.png';

const Footer = () => {
    const [hrefPresencaInternacional, setHrefPresencaInternacional] = useState('');
    const [menuConteudos, setMenuConteudos] = useState([]);

    useEffect(() => {
        function resizeWindow() {
            const presencaInternacional = window.screen.width >= 1200 ? '/institucional#presencainternacional' : '/institucional#p_international';
            setHrefPresencaInternacional(presencaInternacional);
        };

        resizeWindow();
        window.addEventListener("resize", resizeWindow);

        return () => window.removeEventListener("resize", resizeWindow);
    }, [
        hrefPresencaInternacional]);

    useEffect(() => {
        async function getCategoryData() {
            const response = await api.getMenu();
            if (response?.data) {
                setMenuConteudos(response.data);
            }
        }
        getCategoryData();
    }, []);

    return (
        <FooterWrapper id="rodape" className="container-fluid">
            <div className="footer-contents container">
                <div className="footer-container">
                    <div className="footer-links">
                        <div>
                            <ul>
                                <li><strong>Conheça Santillana</strong></li>
                                <li><Link href="/institucional">Sobre Santillana</Link></li>
                                <li><Link href={hrefPresencaInternacional}>Presença internacional</Link></li>
                                <li><Link href="/nossa-historia">Nossa história</Link></li>
                                <li><Link href="/nossas-marcas">Nossas marcas</Link></li>
                                <li><a href="/inovacao">Inovação</a></li>
                                <li><a href="/fundacao-santillana">Impacto social</a></li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li><strong>Conteúdos</strong></li>

                                {
                                    menuConteudos.map((item, idx) => {
                                        return item.slug !== "tudo" ? <li key={idx}><Link href={`/conteudos#${item.slug}`}>{item.name}</Link></li> : null
                                    })
                                }
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li><strong>Nossas soluções</strong></li>
                                <li><Link href="/nossas-solucoes#solucoes-completas">
                                    Didáticos</Link></li>
                                <li><Link href="/nossas-solucoes#idiomas">
                                    Idiomas
                                </Link></li>
                                <li><Link href="/nossas-solucoes#socioemocional">
                                    Socioemocionais</Link></li>
                                <li><Link href="/nossas-solucoes#literatura">
                                    Literatura</Link></li>
                                <li><Link href="/nossas-solucoes#avaliacoes">
                                    Avaliações</Link></li>
                                {/*  <li><Link href="/nossas-solucoes#formacao-pedagogica">
                                    Formação pedagógica</Link></li>
                                <li><Link href="/nossas-solucoes#plataformas-digitais">
                                    Plataformas digitais</Link></li>
                                <li><Link href="/nossas-solucoes#programas-publicos">
                                    Programas públicos</Link></li> */}
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li><strong>Simulador</strong></li>
                                <li><Link href="/simulador">Descubra sua Marca</Link></li>
                            </ul>
                            <ul>
                                <li><strong>Procure-nos</strong></li>
                                <li><Link href="/consultor">Fale com um consultor</Link></li>
                            </ul>
                            <ul>
                                <li><strong>Entre em contato</strong></li>
                                <li><Link href="/contato">Nossos contatos</Link></li>
                                <li><a href="https://santillanabrasil.gupy.io/" target="_blank" rel="noopener noreferrer">Trabalhe conosco</a></li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="footer-icons">
                        <Link href="https://twitter.com/santillanagrupo" className="social-icons">
                            <SocialIcon src={twitter} />
                        </Link>
                        <Link href="/" className="social-icons">
                            <SocialIcon src={youtube} />
                        </Link>
                        <Link href="/" className="social-icons">
                            <SocialIcon src={facebook} />
                        </Link>
                    </div> */}

                </div>

                <div className="footer-copyright">
                    <div className="copyright">
                        <p> © Santillana - Todos os direitos reservados</p>
                        {/* <span>|</span><a href='#' >Termos de uso</a> */}
                        <span className='divisor first'>|</span><a href='https://sta-s3-prd.s3.amazonaws.com/cms/wp-content/uploads/2021/11/08143214/Codigo-de-etica_Santillana-Brasil_2021.pdf' target='_blank' rel="noopener noreferrer">Código de ética</a>
                        <span className='divisor first'>|</span><a href='https://sta-s3-prd.s3.amazonaws.com/cms/wp-content/uploads/2024/05/14103024/Codigo_etica_Prisa.pdf' target='_blank' rel="noopener noreferrer">Código de Ética Prisa</a>
                        <span className='divisor first'>|</span><a href='https://wcpro.s3.amazonaws.com/codigo_etica_dos_fornecedores.pdf' target='_blank' rel="noopener noreferrer">Código Fornecedores</a>
                        {/* <span className='divisor'>|</span><a href='/termos-de-uso' target='_blank' rel="noopener noreferrer">Termos de Uso</a>*/}
                        <span className='divisor'>|</span><a href='/aviso-de-privacidade' target='_blank' rel="noopener noreferrer">Aviso de Privacidade</a>
                        <span className='divisor'>|</span><a href='https://privacyportal-br.onetrust.com/webform/06e5a626-c473-452c-8a1c-a8882064bebc/2aee6837-6504-48eb-aa3b-4ed726d005e2?Fonte=Santillana' target='_blank' rel="noopener noreferrer">Solicitação de Dados</a>
                        <span className='divisor'>|</span><a href='https://www.santillanaeducacao.com.br/canal-de-denuncias/' target='_blank' rel="noopener noreferrer">Canal de denúncias</a>
                        <span className='divisor'>|</span><a href='https://www.santillanaeducacao.com.br/sac/' target='_blank' rel="noopener noreferrer">SAC</a>
                    </div>
                    <span className="version">v. {packageJson.version}</span>
                </div>
            </div>
        </FooterWrapper>
    );
}

export default Footer;